﻿document.querySelectorAll("li.menu").forEach((menu) => {
    menu.addEventListener("click", () => {
        const sub = menu.querySelector("ul.sub-menu");
        sub.classList.toggle("hidden");
    });
});

document.addEventListener("click", (e) => {
    console.log(e.target.closest("li.menu"));
    document.querySelectorAll("ul.sub-menu").forEach((menu) => {
        if (!e.target.closest("li.menu")) {
            menu.classList.add("hidden");
        }
    })
})